<template>
    <div>
        <v-menu
            ref="menu"
            v-if="ready"
            v-model="menu_1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            fullscreen
            :position-x="changeTimePos.x"
            :position-y="changeTimePos.y"
            nudge-top="200px"
            max-width="290px"
            min-width="290px"
            @change="handleChange($event)"
            @input="$emit('closed')"
        >
            <v-time-picker
                v-model="saveTime"
                full-width
                centered
                ampm-in-title
                :allowed-minutes="allowedMinutes()"
                :allowed-hours="allowedHours"
                :min="thisMin"
                :max="max"
                ref="picker"
                @input="handleChange()"
            >
                <v-spacer></v-spacer>
                <div class="time-save d-block">
                    <v-btn text color="white" @click="$emit('closed')">Cancel</v-btn>
                    <v-btn :disabled="$refs.picker && ($refs.picker.value<=min || $refs.picker.value>=max)" dark class="float-right px-5" color="primary" @click="save()">Save</v-btn>
                </div>
            </v-time-picker>
        </v-menu>
    </div>
</template>
<script>

export default {

  name: 'CheckinTimesModal',

    props: {
        allowedHours: {
            required: false,
            type: Array
        },
        item: {
            required: true,
            type: String
        },
        changeTimePos: {
            required: true,
            type: Object
        },
        min: {
            required: false,
        },
        max: {
            required: false,
        },
        selectedDate: {
            required: false,
        }
    },
    components: {
    },
    computed: {
        
    },
    data: () => ({
        menu_1: true,
        ready: false,
        saveTime: null,
        thisMin: null,
    }),
    methods: {
        initData(){
            this.saveTime = JSON.parse(JSON.stringify(this.item));
            this.thisMin = this.min;
            if(this.selectedDate){
                // limit min time to now if date is today
                if(this.selectedDate == this.$moment().format('YYYY-MM-DD')){
                    this.thisMin = '' + this.$moment().hour() + ':' + this.$moment().minutes();
                }
            }
            this.ready = true;
            // console.log(this.min, this.max);
        },
        handleChange(){
            // console.log(this.$refs.picker);
        },
        allowedMinutes(){
            return [0,5,10,15,20,25,30,35,40,45,50,55];
        },
        save(){
            this.$emit('saved', this.saveTime);
            this.close();
        },
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
        this.initData();
    },
    watch: {
        saveTime(){
            // this.$emit('saved', this.saveTime);
        }
    }
}
</script>
