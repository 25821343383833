<template>
    <div class="subtitle-1 times-edit">
        <div class="mt-7" style="min-height: 0 !important;">
            <div class="flexd-center ml-2">
                <v-select 
                    v-model="hours"
                    :items="hourItems()"
                    @change="updateTimes"
                    label="Hour"
                    color="primary"
                    outlined
                    hide-details
                    class="d-inline-block mr-2 font-weight-bold"
                    style="max-width: 80px;"
                    :solo="onDark"
                    :class="inputClasses"
                />
                <span class="font-weight-bold pr-2">:</span>
                <v-select 
                    v-model="minutes"
                    @change="updateTimes"
                    :items="minuteItems()"
                    outlined
                    color="primary"
                    hide-details
                    label="Minute"
                    style="max-width: 80px;"
                    :solo="onDark"
                    :class="inputClasses"
                />
                <v-select 
                    v-model="ampm"
                    :items="['AM', 'PM']"
                    @change="updateTimes"
                    outlined
                    color="primary"
                    hide-details
                    label="AM/PM"
                    style="max-width: 90px;"
                    :solo="onDark"
                    :class="inputClasses"
                    />
            </div>
        </div>
        <v-card id="time-2" dark min-width="110" class="primary d-inline-block time-entry-2 mb-2 mb-sm-0 ml-3" v-if="itemCopy.frequency > 1" hover @click="handleTimeClick(2)">
            <v-card-text class="headline white--text px-0 pb-0 time-entry-2">
                {{ formattedTime(itemCopy.time_2) }}
            </v-card-text>
            <v-card-actions class="text-center d-block body-2 white--text time-entry-2">
                <v-divider class="mb-2" />
                <b>2</b><sup>nd</sup> Check-In
            </v-card-actions>
        </v-card>
        <v-card id="time-3" dark min-width="110" class="primary d-inline-block time-entry-3 ml-3" v-if="itemCopy.frequency > 2" hover @click="handleTimeClick(3)">
            <v-card-text class="headline white--text px-0 pb-0 time-entry-3">
                {{ formattedTime(itemCopy.time_3) }}
            </v-card-text>
            <v-card-actions class="text-center d-block body-2 white--text time-entry-3">
                <v-divider class="mb-2" />
                <b>3</b><sup>rd</sup> Check-In
            </v-card-actions>
        </v-card>
        <!-- Send my check-ins at <p class="d-inline-block mb-0" @click="handleTimeClick($event)" v-html="getTimeString()"></p> -->
        <Times2 :item="changeTime" :allowedHours="allowedHours" :changeTimePos="changeTimePos" @saved="handleTimeChange($event)" v-if="changeTime" @closed="changeTime=null" />
    </div>
</template>
<script>

import Times2 from '@/components/user/dashboard/checkin/Times2'

export default {

  name: 'CheckinSettingsTimes',

    props: {
        item: {
            required: true,
            type: Object
        },
        onDark: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    components: {
        Times2
    },
    computed: {
        allowedHours(){
            let hours = [];
            let time1 = (this.changeTimeVal !== 'time_1' && this.itemCopy.time_1) ? parseInt(this.itemCopy.time_1.substr(0,2)) : 99;
            let time2 = (this.changeTimeVal !== 'time_2' && this.itemCopy.frequency > 1 && this.itemCopy.time_2) ? parseInt(this.itemCopy.time_2.substr(0,2)) : 99;
            let time3 = (this.changeTimeVal !== 'time_3' && this.itemCopy.frequency > 2 && this.itemCopy.time_3) ? parseInt(this.itemCopy.time_3.substr(0,2)) : 99;
            let notAllowed = [time1, time2, time3];
            for(let i=0; i<24; ++i){
                if(notAllowed.indexOf(i) == -1){
                    hours.push(i);
                }
            }
            // console.log('hours', notAllowed, hours);
            // asdf
            return hours;
        }
    },
    data: () => ({
        changeTime: false,
        changeTimePos: {},
        changeTimeVal: null,
        itemCopy: {},
        pickerKey: 0,
        ready: false,
        hours: 4,
        minutes: 0,
        ampm: 'PM',
        inputClasses: 'd-inline-block mr-2 font-weight-bold'
    }),
    methods: {
        initData(){
            this.itemCopy = JSON.parse(JSON.stringify(this.item));
            // this.sortTimes();
            this.initTimes();
            this.ready = true;
            if(this.onDark){
                this.inputClasses += ' white--text';
            }
        },
        hourItems() {
            let items = [];
            for (let i = 1; i < 13; ++i) {
                items.push({
                    value: i,
                    text: i
                });
            }
            return items;
        },
        minuteItems() {
            let items = [];
            for (let i = 0; i < 60; i+=5) {
                items.push({
                    value: (i < 10) ? '0' + i : i,
                    text: (i<10) ? '0' + i : i
                });
            }
            return items;
        },
        handleTimeChange(time){
            this.itemCopy[this.changeTimeVal] = time;
            // sort times
            this.sortTimes();
        },
        updateTimes(){
            let hour = (this.ampm == 'PM') ? this.hours + 12 : '0' + this.hours;
            this.itemCopy.time_1 = hour + ':' + this.minutes;
            this.$emit('updated', this.itemCopy);
        },
        initTimes(){
            let timeparts = this.itemCopy.time_1.split(':');
            // console.log(timeparts);
            this.hours = (parseInt(timeparts[0]) > 12) ? parseInt(timeparts[0]) - 12 : parseInt(timeparts[0]);
            this.minutes = parseInt(timeparts[1]);
            if (this.minutes == 0) this.minutes = "00";
            if (this.minutes == 5) this.minutes = "05";
            this.ampm = (timeparts[0] > 12) ? 'PM' : 'AM';

            // console.log(this.hours, this.minutes, this.ampm);
        },
        sortTimes(){
            let times = [this.itemCopy.time_1, this.itemCopy.time_2, this.itemCopy.time_3];
            times.sort();
            this.itemCopy.time_1 = times[0];
            this.itemCopy.time_2 = times[1];
            this.itemCopy.time_3 = times[2];
            this.$emit('updated', this.itemCopy);
            this.initTimes();
        },
        getTimeString(){
            let ts = '';
            ts = '<span class="time-entry-1">' + this.formattedTime(this.itemCopy.time_1) + '</span>';
            if(this.itemCopy.frequency > 1){
                let concat = (this.itemCopy.frequency == 2) ? ' and ' : ', ';
                ts += concat + '<span class="time-entry-2">' + this.formattedTime(this.itemCopy.time_2) + '</span>';
            }
            if(this.itemCopy.frequency > 2){
                ts += ' and ' + '<span class="time-entry-3">' + this.formattedTime(this.itemCopy.time_3) + '</span>';
            }
            ts += '.';
            return ts;
        },
        handleTimeClick(time){
            // console.log(e.target);
            // this.changeTimePos.x = e.clientX;
            // this.changeTimePos.y = e.clientY;
            let target;
            if (time == 1) {
                target = document.getElementById('time-1');
                this.changeTimeVal = 'time_1';
            }
            if (time == 2) {
                target = document.getElementById('time-2');
                this.changeTimeVal = 'time_2';
            }
            if (time == 3) {
                target = document.getElementById('time-2');
                this.changeTimeVal = 'time_3';
            }
            let pos = target.getBoundingClientRect();
            this.changeTimePos.x = pos.x;
            this.changeTimePos.y = pos.y;
            this.changeTime = this.itemCopy[this.changeTimeVal];
        },
        handleTimeClickOld(e){
            // console.log(e.target);
            this.changeTimePos.x = e.clientX;
            this.changeTimePos.y = e.clientY;
            if (e.target.matches('.time-entry-1')) {
                this.changeTimeVal = 'time_1';
            }
            if (e.target.matches('.time-entry-2')) {
                this.changeTimeVal = 'time_2';
            }
            if (e.target.matches('.time-entry-3')) {
                this.changeTimeVal = 'time_3';
            }
            this.changeTime = this.itemCopy[this.changeTimeVal];
        },
        formattedTime(ts){
            if(!ts){
                return '';
            }
            let parts = ts.split(':');
            let hour = parseInt(parts[0]);
            let ampm = 'am';
            if(hour >= 12){
                ampm = 'pm';
                hour = hour-12;
            }
            if(hour == 0){
                hour = '12';
            }
            return hour + ':' + parts[1] + '' + ampm;
        },
    },
    mounted(){
        this.initData();
    },
    watch: {
    }
}
</script>
<style scoped>
    @import '~vue2-timepicker/dist/VueTimepicker.css';
</style>
